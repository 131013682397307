import { DateTime } from 'luxon'

const sharedMixen = {
  data() {
    return {
      title: 'Red Barchetta',
      author: 'Neil Peart',
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly
    },
    isLocalhost() {
      return window.location.hostname == 'localhost' ? true : false
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null
      const dt = DateTime.fromISO(date).toLocal().toFormat('MM/dd/yyyy')
      return dt
    },
    formatDateDob(date) {
      if (!date) return null
      const dt = DateTime.fromISO(date).toLocal().toFormat('MM/dd/yyyy')
      return dt
    },
    formatDateTime(date) {
      if (!date) return null
      const dt = DateTime.fromISO(date).toLocal().toFormat('MM/dd/yyyy hh:mm a')
      return dt
    },
    formatDatePicker(date) {
      if (!date) return null
      const dt = DateTime.fromISO(date).toLocal().toFormat('yyyy-MM-dd')
      return dt
    },
    formatDateYMD(date) {
      if (!date) return null
      const dt = DateTime.fromISO(date).toLocal().toFormat('yyyy-MM-dd')
      return dt
    },
    dobFormatted() {
      const dt = DateTime.now()
      return dt.toISOString()
    },
  },
}
export default sharedMixen
