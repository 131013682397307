<template>
  <v-container class="mt-4">
    <snackbar :attr="snackbar" />
    <v-row no-gutters>
      <v-col cols="12">
        <v-app-bar
          dense
          color="#ffffff"
          elevation="0"
          class="rounded-tl rounded-tr"
        >
          <v-toolbar-title>Pets</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-dialog v-if="allowEdit" v-model="petDialog" max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn text v-bind="attrs" v-on="on">
                <vue-fontawesome :icon="['fas', 'paw']" class="mr-1" />Add Pet
              </v-btn>
            </template>
            <v-card>
              <v-toolbar elevation="0">
                <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closePetDialog">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="pet.name"
                        required
                        label="Name"
                        hide-details="auto"
                      ></v-text-field>
                      <!-- <v-text-field label="Legal first name*" required></v-text-field> -->
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="pet.breed"
                        :rules="rules"
                        label="Breed"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closePetDialog"> Close </v-btn>
                <v-btn
                  elevation="0"
                  dark
                  class="primary-btn mr-4"
                  @click="savePet"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-app-bar>
        <v-data-table
          hide-default-footer
          :search="search"
          :loading="loading"
          :headers="petHeaders"
          :items="pets"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-dialog v-model="petDialogDelete" width="500">
              <v-card>
                <v-card-title class="headline">
                  <span class="headline">
                    <vue-fontawesome icon="exclamation-triangle" class="mr-2" />
                    Delete Pet?</span
                  >
                </v-card-title>
                <v-card-text>
                  This pet record will be permenantly deleted.
                  <!-- <div class="text--disabled body-2">{{ petId }}</div> -->
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="petDialogDelete = false"> No </v-btn>
                  <v-btn text @click="deletePetConfirm"> Yes </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:item.name="{ item }">
            <b>{{ item.name }}</b>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn v-if="allowEdit" icon small @click="editPet(item)">
              <vue-fontawesome
                small
                :icon="['fas', 'pencil-alt']"
                class="mr-1"
              />
            </v-btn>
            <v-btn v-if="allowEdit" icon small @click="deletePet(item)">
              <vue-fontawesome :icon="['fas', 'trash']" />
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import options from '@/config/valueOptions'
import PropertyService from '@/services/PropertyService'
import Snackbar from '@/components/Snackbar.vue'
import sharedMixin from '@/mixins/sharedMixin.js'

export default {
  name: 'Pets',
  mixins: [sharedMixin],
  components: {
    Snackbar,
  },
  props: {
    propertyId: String,
    pets: Array,
    options: Array,
    allowEdit: Boolean,
  },
  data() {
    return {
      panel: [],
      snackbar: {
        model: false,
        color: '',
        message: '',
        icon: ['fas', 'exclamation-triangle'],
        timeout: 3000,
      },
      activePicker: null,
      dateFormatted: '',
      dateFormattedDisplay: '',
      dateMenu: false,
      search: '',
      loading: false,
      petDialog: false,
      petDialogDelete: false,
      petIndex: -1,
      petId: '',
      // pets: [],
      pet: {
        id: '',
        name: '',
        breed: '',
      },
      petDefault: {
        id: '',
        name: '',
        breed: '',
      },
      petHeaders: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Breed',
          align: 'start',
          sortable: false,
          value: 'breed',
        },
        {
          text: '',
          align: 'end',
          value: 'actions',
          sortable: false,
        },
      ],
      rules: [(v) => !!v || 'This is required'],
      // genderOptions: options.gender,
      // streets: options.streets,
    }
  },
  computed: {
    isAdmin() {
      return this.$store.state.pet.isAdmin || false
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly
    },
    formTitle() {
      return this.petIndex === -1 ? 'New Pet' : 'Edit Pet'
    },
    isEdit() {
      return this.petIndex === -1 ? false : true
    },
  },
  methods: {
    editPet(item) {
      // alert(id)
      // populate dates correctly...

      this.petIndex = this.pets.indexOf(item)
      this.pet = Object.assign({}, item)
      // console.log(item.id)
      this.petId = item.id
      console.log(this.petId)
      this.petDialog = true
    },
    deletePet(item) {
      // alert(id)
      this.petIndex = this.pets.indexOf(item)
      this.pet = Object.assign({}, item)
      this.petId = item.id
      this.petDialogDelete = true
    },
    deletePetConfirm() {
      // const id = this.petId
      // console.log(this.petId)
      // alert(id)
      const data = {
        id: this.petId,
      }
      PropertyService.deletePet(this.propertyId, data)
        .then((response) => {
          // console.log(response)
          this.pets.splice(this.petIndex, 1)
          this.closePetDelete()
          this.snackbar.model = true
          this.snackbar.message = 'Pet deleted'
          this.snackbar.color = 'green'
          this.snackbar.icon = ['far', 'check']
          return response
        })
        .catch((e) => {
          this.closePetDelete()
          this.snackbar.model = true
          this.snackbar.message = 'Pet not deleted'
          this.snackbar.color = 'red'
          this.snackbar.icon = ['fas', 'exclamation-triangle']
          console.log(e)
        })
    },
    async savePet() {
      const data = {
        name: this.pet.name,
        breed: this.pet.breed,
      }
      if (this.petIndex > -1) {
        // alert('updated')
        // update
        data.id = this.pet.id
        data.updatedBy = this.$store.state.user.id
        // set fullName...
        Object.assign(this.pets[this.petIndex], this.pet)
        // console.log(this.pet.dateOfBirth)
        PropertyService.updatePet(this.propertyId, data)
          .then(() => {
            this.closePetDialog()
            this.snackbar.model = true
            this.snackbar.message = 'Pet updated'
            this.snackbar.color = 'green'
            this.snackbar.icon = ['far', 'check']
          })
          .catch((e) => {
            this.closePetDialog()
            this.snackbar.model = true
            this.snackbar.message = 'Pet error'
            this.snackbar.color = 'red'
            this.snackbar.icon = ['fas', 'exclamation-triangle']
            console.log(e)
          })
      } else {
        data.createdBy = this.$store.state.user.id
        this.saveNewPet(data)
      }
    },
    saveNewPet(petData) {
      PropertyService.addPet(this.propertyId, petData)
        .then((response) => {
          const newId = response.data.id
          // console.log(petData)
          // console.log(newId)
          const newPet = Object.assign(this.pet)
          newPet.id = newId
          newPet.fullName = this.pet.firstName + ' ' + this.pet.lastName
          newPet.dateOfBirth = this.dateFormatted
          // const newPet = Object.assign({ id: newId }, this.pet)
          // console.log(this.pet)
          // console.log(newPet)
          this.pets.push(newPet)
          this.closePetDialog()
          this.snackbar.model = true
          this.snackbar.message = 'Pet created'
          this.snackbar.color = 'green'
          this.snackbar.icon = ['far', 'check']
          // console.log('Created new forum successfully! ' + response.data.id)
          // this.submitted = true
        })
        .catch((err) => {
          this.snackbar.model = true
          this.snackbar.message = 'No pet created'
          this.snackbar.color = 'red'
          this.snackbar.icon = ['fas', 'exclamation-triangle']
          console.log(err)
        })
    },
    closePetDialog() {
      this.petDialog = false
      // we need to clear date fields if they were set
      this.dateAssignedMDY = null
      this.dateAssignedYMD = null
      this.dateEndedMDY = null
      this.dateEndedYMD = null
      this.$nextTick(() => {
        this.pet = Object.assign({}, this.petDefault)
        this.petIndex = -1
      })
    },
    closePetDelete() {
      this.petDialogDelete = false
      this.$nextTick(() => {
        this.pet = Object.assign({}, this.petDefault)
        this.petIndex = -1
      })
    },
  },
}
</script>
