<template>
  <v-container class="mt-4">
    <snackbar :attr="snackbar" />
    <v-row no-gutters>
      <v-col cols="12">
        <v-app-bar
          dense
          color="#ffffff"
          elevation="0"
          class="rounded-tl rounded-tr"
        >
          <!-- <v-app-bar
          dense
          dark
          color="#29526b"
          elevation="0"
          class="rounded-tl rounded-tr"
        > -->
          <v-toolbar-title>Residents</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-dialog v-if="allowEdit" v-model="residentDialog" max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn text v-bind="attrs" v-on="on">
                <vue-fontawesome :icon="['fas', 'user-plus']" class="mr-1" />Add
                Resident
              </v-btn>
            </template>
            <v-card>
              <v-toolbar elevation="0">
                <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeResidentDialog">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="resident.firstName"
                        required
                        id="firstName"
                        name="firstName"
                        label="First Name"
                        hide-details="auto"
                      ></v-text-field>
                      <!-- <v-text-field label="Legal first name*" required></v-text-field> -->
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="resident.lastName"
                        :rules="rules"
                        id="lastName"
                        name="lastName"
                        label="Last Name"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="resident.email"
                        :rules="emailRules"
                        id="email"
                        name="email"
                        label="Email"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" lg="6">
                      <v-text-field
                        v-model="resident.mobilePhone"
                        v-mask="'(###) ###-####'"
                        label="Mobile"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" lg="6">
                      <v-text-field
                        v-model="resident.homePhone"
                        v-mask="'(###) ###-####'"
                        label="Home"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closeResidentDialog"> Close </v-btn>
                <v-btn
                  elevation="0"
                  dark
                  class="primary-btn mr-4"
                  @click="saveResident"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-app-bar>
        <v-data-table
          hide-default-footer
          :search="search"
          :loading="loading"
          :headers="residentHeaders"
          :items="residents"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-dialog v-model="residentDialogDelete" width="500">
              <v-card>
                <v-card-title class="headline">
                  <span class="headline">
                    <vue-fontawesome icon="exclamation-triangle" class="mr-2" />
                    Delete Resident?</span
                  >
                </v-card-title>
                <v-card-text>
                  This resident record will be permenantly deleted.
                  <!-- <div class="text--disabled body-2">{{ residentId }}</div> -->
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="residentDialogDelete = false"> No </v-btn>
                  <v-btn text @click="deleteResidentConfirm"> Yes </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:item.fullNam="{ item }">
            <b>{{ item.fullName }}</b>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn v-if="allowEdit" icon small @click="editResident(item)">
              <vue-fontawesome small :icon="['fas', 'pencil-alt']" />
            </v-btn>
            <v-btn v-if="allowEdit" icon small @click="deleteResident(item)">
              <vue-fontawesome :icon="['fas', 'trash']" />
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import options from '@/config/valueOptions'
import PropertyService from '@/services/PropertyService'
import Snackbar from '@/components/Snackbar.vue'
import sharedMixin from '@/mixins/sharedMixin.js'

export default {
  name: 'Residents',
  mixins: [sharedMixin],
  components: {
    Snackbar,
  },
  props: {
    propertyId: String,
    residents: Array,
    options: Array,
    allowEdit: Boolean,
  },
  data() {
    return {
      panel: [],
      snackbar: {
        model: false,
        color: '',
        message: '',
        icon: ['fas', 'exclamation-triangle'],
        timeout: 3000,
      },
      search: '',
      loading: false,
      residentDialog: false,
      residentDialogDelete: false,
      residentIndex: -1,
      residentId: '',
      // residents: [],
      resident: {
        id: '',
        firstName: '',
        lastName: '',
        mobilePhone: '',
        dateAssigned: '',
        dateEnded: '',
        reasonEnded: '',
        frequency: '',
        frequencyOther: '',
        frequencyTotal: '',
      },
      residentDefault: {
        id: '',
        firstName: '',
        lastName: '',
        mobilePhone: '',
        dateAssigned: '',
        dateEnded: '',
        reasonEnded: '',
        frequency: '',
        frequencyOther: '',
        frequencyTotal: '',
      },
      residentHeaders: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'fullName',
        },
        {
          text: 'Email',
          align: 'start',
          sortable: false,
          value: 'email',
        },
        {
          text: 'Mobile Phone',
          align: 'start',
          sortable: false,
          value: 'mobilePhone',
        },
        {
          text: 'Home Phone',
          align: 'start',
          sortable: false,
          value: 'homePhone',
        },
        {
          text: '',
          align: 'end',
          value: 'actions',
          sortable: false,
        },
      ],
      rules: [(v) => !!v || 'This is required'],
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
      // genderOptions: options.gender,
      // streets: options.streets,
    }
  },
  mounted() {},
  computed: {
    isAdmin() {
      return this.$store.state.resident.isAdmin || false
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly
    },
    formTitle() {
      return this.residentIndex === -1 ? 'New Resident' : 'Edit Resident'
    },
    isEdit() {
      return this.residentIndex === -1 ? false : true
    },
  },
  methods: {
    editResident(item) {
      // alert(id)
      // populate dates correctly...

      this.residentIndex = this.residents.indexOf(item)
      this.resident = Object.assign({}, item)
      // console.log(item.id)
      // this.dateAssignedYMD = this.formatDateYMD(this.resident.dateAssigned)
      // this.dateAssignedMDY = this.formatDate(this.resident.dateAssigned)
      // this.dateEndedYMD = this.formatDateYMD(this.resident.dateEnded)
      // this.dateEndedMDY = this.formatDate(this.resident.dateEnded)
      this.residentId = item.id
      this.residentDialog = true
    },
    deleteResident(item) {
      // alert(id)
      this.residentIndex = this.residents.indexOf(item)
      this.resident = Object.assign({}, item)
      this.residentId = item.id
      this.residentDialogDelete = true
    },
    deleteResidentConfirm() {
      // const id = this.residentId
      // console.log(this.residentId)
      // alert(id)
      const data = {
        id: this.residentId,
      }
      PropertyService.deletePerson(this.propertyId, data)
        .then((response) => {
          // console.log(response)
          this.residents.splice(this.residentIndex, 1)
          this.closeResidentDelete()
          this.snackbar.model = true
          this.snackbar.message = 'Resident deleted'
          this.snackbar.color = 'green'
          this.snackbar.icon = ['far', 'check']
          return response
        })
        .catch((e) => {
          this.closeResidentDelete()
          this.snackbar.model = true
          this.snackbar.message = 'Resident not deleted'
          this.snackbar.color = 'red'
          this.snackbar.icon = ['fas', 'exclamation-triangle']
          console.log(e)
        })
    },
    async saveResident() {
      const data = {
        firstName: this.resident.firstName,
        lastName: this.resident.lastName,
        email: this.resident.email,
        mobilePhone: this.resident.mobilePhone,
        homePhone: this.resident.homePhone,
      }
      this.resident.fullName =
        this.resident.firstName + ' ' + this.resident.lastName
      if (this.residentIndex > -1) {
        // alert('updated')
        // update
        data.id = this.resident.id
        data.updatedBy = this.$store.state.user.id
        // set fullName...
        Object.assign(this.residents[this.residentIndex], this.resident)
        PropertyService.updatePerson(this.propertyId, data)
          .then(() => {
            this.closeResidentDialog()
            this.snackbar.model = true
            this.snackbar.message = 'Resident updated'
            this.snackbar.color = 'green'
            this.snackbar.icon = ['far', 'check']
          })
          .catch((e) => {
            this.closeResidentDialog()
            this.snackbar.model = true
            this.snackbar.message = 'Resident error'
            this.snackbar.color = 'red'
            this.snackbar.icon = ['fas', 'exclamation-triangle']
            console.log(e)
          })
      } else {
        data.createdBy = this.$store.state.user.id
        this.saveNewResident(data)
      }
    },
    saveNewResident(residentData) {
      PropertyService.addPerson(this.propertyId, residentData)
        .then((response) => {
          const newId = response.data.id
          // console.log(newId)
          const newResident = Object.assign(this.resident)
          newResident.id = newId
          newResident.fullName =
            this.resident.firstName + ' ' + this.resident.lastName
          // const newResident = Object.assign({ id: newId }, this.resident)
          // console.log(newResident)
          this.residents.push(newResident)
          this.closeResidentDialog()
          this.snackbar.model = true
          this.snackbar.message = 'Resident created'
          this.snackbar.color = 'green'
          this.snackbar.icon = ['far', 'check']
          // console.log('Created new forum successfully! ' + response.data.id)
          // this.submitted = true
        })
        .catch((err) => {
          this.snackbar.model = true
          this.snackbar.message = 'No resident created'
          this.snackbar.color = 'red'
          this.snackbar.icon = ['fas', 'exclamation-triangle']
          console.log(err)
        })
    },
    closeResidentDialog() {
      this.residentDialog = false
      // we need to clear date fields if they were set
      this.dateAssignedMDY = null
      this.dateAssignedYMD = null
      this.dateEndedMDY = null
      this.dateEndedYMD = null
      this.$nextTick(() => {
        this.resident = Object.assign({}, this.residentDefault)
        this.residentIndex = -1
      })
    },
    closeResidentDelete() {
      this.residentDialogDelete = false
      this.$nextTick(() => {
        this.resident = Object.assign({}, this.residentDefault)
        this.residentIndex = -1
      })
    },
  },
}
</script>
