<template>
  <v-container class="mt-4">
    <snackbar :attr="snackbar" />
    <v-row no-gutters>
      <v-col cols="12">
        <v-app-bar
          dense
          color="#ffffff"
          elevation="0"
          class="rounded-tl rounded-tr"
        >
          <v-toolbar-title>Dues</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-dialog v-if="allowEdit" v-model="dueDialog" max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn text v-bind="attrs" v-on="on">
                <vue-fontawesome
                  :icon="['fas', 'dollar-sign']"
                  class="mr-1"
                />Add Due
              </v-btn>
            </template>
            <v-card>
              <v-toolbar elevation="0">
                <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeDueDialog">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="due.year"
                        required
                        label="Year"
                        hide-details="auto"
                      ></v-text-field>
                      <!-- <v-text-field label="Legal first name*" required></v-text-field> -->
                    </v-col>
                    <!-- <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="due.breed"
                        :rules="rules"
                        label="Breed"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col> -->
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closeDueDialog"> Close </v-btn>
                <v-btn
                  elevation="0"
                  dark
                  class="primary-btn mr-4"
                  @click="saveDue"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-app-bar>
        <v-data-table
          hide-default-footer
          :search="search"
          :loading="loading"
          :headers="dueHeaders"
          :items="dues"
          sort-by="year"
          :sort-desc="true"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-dialog v-model="dueDialogDelete" width="500">
              <v-card>
                <v-card-title class="headline">
                  <span class="headline">
                    <vue-fontawesome icon="exclamation-triangle" class="mr-2" />
                    Delete Due?</span
                  >
                </v-card-title>
                <v-card-text>
                  This due record will be permenantly deleted.
                  <!-- <div class="text--disabled body-2">{{ dueId }}</div> -->
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="dueDialogDelete = false"> No </v-btn>
                  <v-btn text @click="deleteDueConfirm"> Yes </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:item.year="{ item }">
            <b>{{ item.year }}</b>
          </template>
          <template v-slot:item.isPaid="{ item }">
            <vue-fontawesome
              v-if="item.isPaid"
              :icon="['far', 'check']"
              class="ml-2"
            />
            <vue-fontawesome v-else :icon="['far', 'ban']" class="ml-2" />
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn v-if="allowEdit" icon small @click="editDue(item)">
              <vue-fontawesome small :icon="['fas', 'pencil-alt']" />
            </v-btn>
            <v-btn v-if="allowEdit" icon small @click="deleteDue(item)">
              <vue-fontawesome :icon="['fas', 'trash']" />
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import options from '@/config/valueOptions'
import PropertyService from '@/services/PropertyService'
import Snackbar from '@/components/Snackbar.vue'
import sharedMixin from '@/mixins/sharedMixin.js'

export default {
  name: 'Dues',
  mixins: [sharedMixin],
  components: {
    Snackbar,
  },
  props: {
    propertyId: String,
    dues: Array,
    options: Array,
    allowEdit: Boolean,
  },
  data() {
    return {
      panel: [],
      snackbar: {
        model: false,
        color: '',
        message: '',
        icon: ['fas', 'exclamation-triangle'],
        timeout: 3000,
      },
      activePicker: null,
      dateFormatted: '',
      dateFormattedDisplay: '',
      dateMenu: false,
      search: '',
      loading: false,
      dueDialog: false,
      dueDialogDelete: false,
      dueIndex: -1,
      dueId: '',
      // dues: [],
      due: {
        id: '',
        year: '',
        isPaid: '',
      },
      dueDefault: {
        id: '',
        year: '',
        isPaid: '',
      },
      dueHeaders: [
        {
          text: 'Year',
          align: 'start',
          sortable: false,
          value: 'year',
        },
        {
          text: 'Paid',
          align: 'start',
          sortable: false,
          value: 'isPaid',
        },
        {
          text: '',
          align: 'end',
          value: 'actions',
          sortable: false,
        },
      ],
      rules: [(v) => !!v || 'This is required'],
      // genderOptions: options.gender,
      // streets: options.streets,
    }
  },
  computed: {
    isAdmin() {
      return this.$store.state.due.isAdmin || false
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly
    },
    formTitle() {
      return this.dueIndex === -1 ? 'New Due' : 'Edit Due'
    },
    isEdit() {
      return this.dueIndex === -1 ? false : true
    },
  },
  methods: {
    editDue(item) {
      // alert(id)
      // populate dates correctly...

      this.dueIndex = this.dues.indexOf(item)
      this.due = Object.assign({}, item)
      // console.log(item.id)
      this.dueId = item.id
      console.log(this.dueId)
      this.dueDialog = true
    },
    deleteDue(item) {
      // alert(id)
      this.dueIndex = this.dues.indexOf(item)
      this.due = Object.assign({}, item)
      this.dueId = item.id
      this.dueDialogDelete = true
    },
    deleteDueConfirm() {
      // const id = this.dueId
      // console.log(this.dueId)
      // alert(id)
      const data = {
        id: this.dueId,
      }
      PropertyService.deleteDue(this.propertyId, data)
        .then((response) => {
          // console.log(response)
          this.dues.splice(this.dueIndex, 1)
          this.closeDueDelete()
          this.snackbar.model = true
          this.snackbar.message = 'Due deleted'
          this.snackbar.color = 'green'
          this.snackbar.icon = ['far', 'check']
          return response
        })
        .catch((e) => {
          this.closeDueDelete()
          this.snackbar.model = true
          this.snackbar.message = 'Due not deleted'
          this.snackbar.color = 'red'
          this.snackbar.icon = ['fas', 'exclamation-triangle']
          console.log(e)
        })
    },
    async saveDue() {
      const data = {
        year: this.due.year,
        isPaid: true,
      }
      if (this.dueIndex > -1) {
        // alert('updated')
        // update
        data.id = this.due.id
        data.updatedBy = this.$store.state.user.id
        // set fullName...
        Object.assign(this.dues[this.dueIndex], this.due)
        // console.log(this.due.dateOfBirth)
        PropertyService.updateDue(this.propertyId, data)
          .then(() => {
            this.closeDueDialog()
            this.snackbar.model = true
            this.snackbar.message = 'Due updated'
            this.snackbar.color = 'green'
            this.snackbar.icon = ['far', 'check']
          })
          .catch((e) => {
            this.closeDueDialog()
            this.snackbar.model = true
            this.snackbar.message = 'Due error'
            this.snackbar.color = 'red'
            this.snackbar.icon = ['fas', 'exclamation-triangle']
            console.log(e)
          })
      } else {
        data.createdBy = this.$store.state.user.id
        this.saveNewDue(data)
      }
    },
    saveNewDue(dueData) {
      PropertyService.addDue(this.propertyId, dueData)
        .then((response) => {
          const newId = response.data.id
          // console.log(dueData)
          // console.log(newId)
          const newDue = Object.assign(this.due)
          newDue.id = newId
          newDue.isPaid = true
          // console.log(this.due)
          // console.log(newDue)
          this.dues.push(newDue)
          this.closeDueDialog()
          this.snackbar.model = true
          this.snackbar.message = 'Due created'
          this.snackbar.color = 'green'
          this.snackbar.icon = ['far', 'check']
          // console.log('Created new forum successfully! ' + response.data.id)
          // this.submitted = true
        })
        .catch((err) => {
          this.snackbar.model = true
          this.snackbar.message = 'No due created'
          this.snackbar.color = 'red'
          this.snackbar.icon = ['fas', 'exclamation-triangle']
          console.log(err)
        })
    },
    closeDueDialog() {
      this.dueDialog = false
      // we need to clear date fields if they were set
      this.dateAssignedMDY = null
      this.dateAssignedYMD = null
      this.dateEndedMDY = null
      this.dateEndedYMD = null
      this.$nextTick(() => {
        this.due = Object.assign({}, this.dueDefault)
        this.dueIndex = -1
      })
    },
    closeDueDelete() {
      this.dueDialogDelete = false
      this.$nextTick(() => {
        this.due = Object.assign({}, this.dueDefault)
        this.dueIndex = -1
      })
    },
  },
}
</script>
