<template>
  <v-container class="mt-4">
    <snackbar :attr="snackbar" />
    <v-row no-gutters>
      <v-col cols="12">
        <v-app-bar
          dense
          color="#ffffff"
          elevation="0"
          class="rounded-tl rounded-tr"
        >
          <v-toolbar-title>Children</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- {{ allowEdit }} -->
          <v-dialog v-if="allowEdit" v-model="childDialog" max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn text v-bind="attrs" v-on="on">
                <vue-fontawesome :icon="['fas', 'child']" class="mr-1" />Add
                Child
              </v-btn>
            </template>
            <v-card>
              <v-toolbar elevation="0">
                <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeChildDialog">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="child.firstName"
                        required
                        id="firstName"
                        name="firstName"
                        label="First Name"
                        hide-details="auto"
                      ></v-text-field>
                      <!-- <v-text-field label="Legal first name*" required></v-text-field> -->
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="child.lastName"
                        :rules="rules"
                        id="lastName"
                        name="lastName"
                        label="Last Name"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-menu
                        ref="dateMenu"
                        v-model="dateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="dateFormattedDisplay"
                            label="Birth date"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            ><vue-fontawesome
                              :icon="['fas', 'calendar-alt']"
                              slot="prepend-inner"
                              class="mt-1 mr-1"
                            />{{
                              formatDatePicker(child.dateOfBirth)
                            }}</v-text-field
                          >
                        </template>
                        <v-date-picker
                          :active-picker.sync="activePicker"
                          v-model="dateFormatted"
                          min="1920-01-01"
                          @change="save"
                        ></v-date-picker>
                        <!-- <v-date-picker
                          :active-picker.sync="activePicker"
                          v-model="dateFormatted"
                          min="1920-01-01"
                          @change="save"
                          ><v-btn text @click="clearDate()"
                            >Clear
                          </v-btn></v-date-picker
                        > -->
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closeChildDialog"> Close </v-btn>
                <v-btn
                  elevation="0"
                  dark
                  class="primary-btn mr-4"
                  @click="saveChild"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-app-bar>
        <v-data-table
          hide-default-footer
          :search="search"
          :loading="loading"
          :headers="childHeaders"
          :items="children"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-dialog v-model="childDialogDelete" width="500">
              <v-card>
                <v-card-title class="headline">
                  <span class="headline">
                    <vue-fontawesome icon="exclamation-triangle" class="mr-2" />
                    Delete Child?</span
                  >
                </v-card-title>
                <v-card-text>
                  This child record will be permenantly deleted.
                  <!-- <div class="text--disabled body-2">{{ childId }}</div> -->
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="childDialogDelete = false"> No </v-btn>
                  <v-btn text @click="deleteChildConfirm"> Yes </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:item.fullName="{ item }">
            <b>{{ item.fullName }}</b>
          </template>
          <template v-slot:item.dateOfBirth="{ item }">
            {{ formatDate(item.dateOfBirth) }}
            <span v-if="item.dateOfBirth" class="text--secondary"
              >({{ age(item.dateOfBirth) }})</span
            >
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn v-if="allowEdit" icon small @click="editChild(item)">
              <vue-fontawesome small :icon="['fas', 'pencil-alt']" />
            </v-btn>
            <v-btn v-if="allowEdit" icon small @click="deleteChild(item)">
              <vue-fontawesome :icon="['fas', 'trash']" />
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import options from '@/config/valueOptions'
import PropertyService from '@/services/PropertyService'
import Snackbar from '@/components/Snackbar.vue'
import sharedMixin from '@/mixins/sharedMixin.js'
import { DateTime } from 'luxon'

export default {
  name: 'Children',
  mixins: [sharedMixin],
  components: {
    Snackbar,
  },
  props: {
    propertyId: String,
    children: Array,
    options: Array,
    allowEdit: Boolean,
  },
  data() {
    return {
      panel: [],
      snackbar: {
        model: false,
        color: '',
        message: '',
        icon: ['fas', 'exclamation-triangle'],
        timeout: 3000,
      },
      activePicker: null,
      dateFormatted: '',
      dateFormattedDisplay: '',
      dateMenu: false,
      search: '',
      loading: false,
      childDialog: false,
      childDialogDelete: false,
      childIndex: -1,
      childId: '',
      // children: [],
      child: {
        id: '',
        firstName: '',
        lastName: '',
        dateOfBirth: null,
      },
      childDefault: {
        id: '',
        firstName: '',
        lastName: '',
        dateOfBirth: null,
      },
      childHeaders: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'firstName',
        },
        {
          text: 'Date of Birth',
          align: 'start',
          sortable: false,
          value: 'dateOfBirth',
        },
        // {
        //   text: 'Mobile Phone',
        //   align: 'start',
        //   filterable: true,
        //   value: 'mobilePhone',
        // },
        // {
        //   text: 'Home Phone',
        //   align: 'start',
        //   filterable: true,
        //   value: 'homePhone',
        // },
        {
          text: '',
          align: 'end',
          value: 'actions',
          sortable: false,
        },
      ],
      rules: [(v) => !!v || 'This is required'],
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
      // genderOptions: options.gender,
      // streets: options.streets,
    }
  },
  watch: {
    dateMenu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },
  // watch: {
  //   date(val) {
  //     if (val) {
  //       this.dateFormatted = this.formatDate(this.date)
  //     }
  //   },
  //   dateMenu(val) {
  //     val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
  //   },
  // },
  mounted() {},
  computed: {
    isAdmin() {
      return this.$store.state.child.isAdmin || false
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly
    },
    formTitle() {
      return this.childIndex === -1 ? 'New Child' : 'Edit Child'
    },
    isEdit() {
      return this.childIndex === -1 ? false : true
    },
  },
  methods: {
    save(date) {
      this.$refs.dateMenu.save(date)
      this.dateFormattedDisplay = this.formatDate(date)
    },
    // clearDate() {
    //   this.dateFormatted = ''
    //   this.dateFormattedDisplay = ''
    //   this.$refs.dateMenu.save()
    // },
    age(dateOfBirth) {
      if (!dateOfBirth) {
        return
      } else {
        const today = new Date()
        const dob = DateTime.fromISO(dateOfBirth)
        // console.log(dob)
        let age = today.getFullYear() - dob.toFormat('yyyy')
        const m = today.getMonth() - dob.toFormat('mm')
        if (m < 0 || (m === 0 && today.getDate() < dob.getDate())) {
          age = age - 1
        }
        return age
      }
    },
    editChild(item) {
      // alert(id)
      // populate dates correctly...

      this.childIndex = this.children.indexOf(item)
      this.child = Object.assign({}, item)
      this.dateFormatted = this.formatDatePicker(this.child.dateOfBirth)
      this.dateFormattedDisplay = this.formatDate(this.child.dateOfBirth)
      // this.dateAssignedYMD = this.formatDateYMD(this.child.dateAssigned)
      // this.dateAssignedMDY = this.formatDate(this.child.dateAssigned)
      // this.dateEndedYMD = this.formatDateYMD(this.child.dateEnded)
      // this.dateEndedMDY = this.formatDate(this.child.dateEnded)
      this.childId = item.id
      // console.log(this.childId)
      this.childDialog = true
      // console.log(this.childDialog)
    },
    deleteChild(item) {
      // alert(id)
      this.childIndex = this.children.indexOf(item)
      this.child = Object.assign({}, item)
      this.childId = item.id
      this.childDialogDelete = true
    },
    deleteChildConfirm() {
      // const id = this.childId
      // console.log(this.childId)
      // alert(id)
      const data = {
        id: this.childId,
      }
      PropertyService.deleteChild(this.propertyId, data)
        .then((response) => {
          // console.log(response)
          this.children.splice(this.childIndex, 1)
          this.closeChildDelete()
          this.snackbar.model = true
          this.snackbar.message = 'Child deleted'
          this.snackbar.color = 'green'
          this.snackbar.icon = ['far', 'check']
          return response
        })
        .catch((e) => {
          this.closeChildDelete()
          this.snackbar.model = true
          this.snackbar.message = 'Child not deleted'
          this.snackbar.color = 'red'
          this.snackbar.icon = ['fas', 'exclamation-triangle']
          console.log(e)
        })
    },
    async saveChild() {
      const data = {
        firstName: this.child.firstName,
        lastName: this.child.lastName,
        dateOfBirth: this.dateFormatted,
      }
      this.child.fullName = this.child.firstName + ' ' + this.child.lastName
      this.child.dateOfBirth = this.dateFormatted
      if (this.childIndex > -1) {
        // alert('updated')
        // update
        data.id = this.child.id
        data.updatedBy = this.$store.state.user.id
        // set fullName...
        Object.assign(this.children[this.childIndex], this.child)
        // console.log(this.child.dateOfBirth)
        PropertyService.updateChild(this.propertyId, data)
          .then(() => {
            this.closeChildDialog()
            this.snackbar.model = true
            this.snackbar.message = 'Child updated'
            this.snackbar.color = 'green'
            this.snackbar.icon = ['far', 'check']
          })
          .catch((e) => {
            this.closeChildDialog()
            this.snackbar.model = true
            this.snackbar.message = 'Child error'
            this.snackbar.color = 'red'
            this.snackbar.icon = ['fas', 'exclamation-triangle']
            console.log(e)
          })
      } else {
        data.createdBy = this.$store.state.user.id
        this.saveNewChild(data)
      }
    },
    saveNewChild(childData) {
      PropertyService.addChild(this.propertyId, childData)
        .then((response) => {
          const newId = response.data.id
          // console.log(childData)
          // console.log(newId)
          const newChild = Object.assign(this.child)
          newChild.id = newId
          newChild.fullName = this.child.firstName + ' ' + this.child.lastName
          newChild.dateOfBirth = this.dateFormatted
          // const newChild = Object.assign({ id: newId }, this.child)
          // console.log(this.child)
          // console.log(newChild)
          this.children.push(newChild)
          this.closeChildDialog()
          this.snackbar.model = true
          this.snackbar.message = 'Child created'
          this.snackbar.color = 'green'
          this.snackbar.icon = ['far', 'check']
          // console.log('Created new forum successfully! ' + response.data.id)
          // this.submitted = true
        })
        .catch((err) => {
          this.snackbar.model = true
          this.snackbar.message = 'No child created'
          this.snackbar.color = 'red'
          this.snackbar.icon = ['fas', 'exclamation-triangle']
          console.log(err)
        })
    },
    closeChildDialog() {
      this.childDialog = false
      // we need to clear date fields if they were set
      this.dateAssignedMDY = null
      this.dateAssignedYMD = null
      this.dateEndedMDY = null
      this.dateEndedYMD = null
      this.$nextTick(() => {
        this.child = Object.assign({}, this.childDefault)
        this.childIndex = -1
      })
    },
    closeChildDelete() {
      this.childDialogDelete = false
      this.$nextTick(() => {
        this.child = Object.assign({}, this.childDefault)
        this.childIndex = -1
      })
    },
  },
}
</script>
