<template>
  <v-container class="mt-4">
    <snackbar :attr="snackbar" />
    <v-row>
      <v-col cols="12">
        <h2 class="font-weight-black citizen-header">
          {{ property.fullAddress }}
        </h2>
        <!-- {{ isCurrent }} -->
        <v-chip v-if="isCurrent" color="#5cbbf6" text-color="white" class="ml-2"
          ><vue-fontawesome
            :icon="['far', 'check']"
            class="mr-1"
          ></vue-fontawesome
          >Paid/Current</v-chip
        >
        <!-- {{ currentYear }} - {{ property.dues[0].year }} -->
        <!-- edit: {{ canEdit }}, my property: {{ myProperty }} -->
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <residents
          :propertyId="property.id"
          :residents="property.people"
          :allowEdit="allowEdit"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="6">
        <children
          :propertyId="property.id"
          :children="property.children"
          :allowEdit="allowEdit"
        />
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="6">
        <pets
          :propertyId="property.id"
          :pets="property.pets"
          :allowEdit="allowEdit"
        />
      </v-col>
    </v-row>
    <v-row v-if="isAdmin">
      <v-col cols="12" sm="12" md="12" lg="6">
        <dues
          :propertyId="property.id"
          :dues="property.dues"
          :allowEdit="allowEdit"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12"> </v-col>
    </v-row>
  </v-container>
</template>

<script>
import options from '@/config/valueOptions'
import PropertyService from '@/services/PropertyService'
import Snackbar from '@/components/Snackbar.vue'
import Residents from '@/components/property/Residents.vue'
import Children from '@/components/property/Children.vue'
import Pets from '@/components/property/Pets.vue'
import Dues from '@/components/property/Dues.vue'
import sharedMixin from '@/mixins/sharedMixin.js'

export default {
  name: 'Property',
  mixins: [sharedMixin],
  components: {
    Snackbar,
    Residents,
    Children,
    Pets,
    Dues,
  },
  data() {
    return {
      panel: [],
      snackbar: {
        model: false,
        color: '',
        message: '',
        icon: ['fas', 'exclamation-triangle'],
        timeout: 3000,
      },
      search: '',
      loading: false,
      property: {
        fullAddress: '',
        streetNumber: '',
        streetName: '',
        people: [],
        children: [],
        pets: [],
        dues: [],
      },
      propertyDues: [],
      // residents: [],
      // children: [],
      // pets: [],
      // dues: [],
      residentHeaders: [
        {
          text: 'Name',
          align: 'start',
          filterable: true,
          value: 'fullName',
        },
        {
          text: 'Email',
          align: 'start',
          filterable: true,
          value: 'email',
        },
        {
          text: 'MobilePhone',
          align: 'start',
          filterable: true,
          value: 'mobilePhone',
        },
        // {
        //   text: 'Last Updated',
        //   align: 'start',
        //   filterable: true,
        //   value: 'updatedAt',
        // },
        {
          text: 'Actions',
          align: 'end',
          value: 'actions',
          sortable: false,
        },
      ],
      rules: [(v) => !!v || 'This is required'],
      genderOptions: options.gender,
      streets: options.streets,
      users: [],
    }
  },
  watch: {
    $route() {
      this.getProperty()
    },
  },
  created() {
    this.getProperty()
  },
  mounted() {},
  computed: {
    currentYear() {
      return this.$store.state.currentYear || null
    },
    isCurrent() {
      return this.property.dues.some(
        (data) => data.year === Number(this.$store.state.currentYear)
      )
    },
    isAdmin() {
      return this.$store.state.user.isAdmin || false
    },
    canEdit() {
      return this.$store.state.user.isAdmin
    },
    myProperty() {
      return this.$store.state.user.property == this.$route.params.id
        ? true
        : false
    },
    allowEdit() {
      return this.canEdit || this.myProperty ? true : false
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly
    },
    formTitle() {
      return this.participationIndex === -1 ? 'New Citizen' : 'Edit Citizen'
    },
    isEdit() {
      return this.participationIndex === -1 ? false : true
    },
  },
  methods: {
    async getProperty() {
      this.loading = true
      try {
        const propertyResponse = await PropertyService.get(
          this.$route.params.id
        )
        if (!propertyResponse) {
          return
        }
        this.property = propertyResponse.data
        // this.propertyDues = this.property.dues
        // console.log(this.propertyDues)
        // console.log(this.$store.state.currentYear)
        // console.log(this.property.dues[0].year)
        // const currentYear = this.$store.state.currentYear
        // const check = this.propertyDues.filter(function (el) {
        //   if (el.year == currentYear) {
        //     return el // returns length = 1 (object exists in array)
        //     // return el // returns length = 1 (object exists in array)
        //   }
        // })
        // console.log(Boolean(check))
        // console.log(
        //   this.property.dues.year.includes(this.$store.state.currentYear)
        // )
      } catch (error) {
        this.snackbar.model = true
        this.snackbar.message = error
        this.snackbar.color = 'red'
        this.snackbar.icon = ['fas', 'exclamation-triangle']
        console.log(error)
      }
      this.loading = false
    },
  },
}
</script>
<style lang="scss" scoped>
.v-tab {
  font-weight: normal;
  text-transform: none;
  letter-spacing: 0 !important;
}
.citizen-header {
  letter-spacing: -0.5px !important;
}
.hr-sect {
  font-weight: 500;
  display: flex;
  flex-basis: 100%;
  align-items: center;
  // color: rgba(0, 0, 0, 0.75);
  margin: 8px 0;

  &:before,
  &:after {
    content: '';
    flex-grow: 1;
    background: rgba(0, 0, 0, 0.25);
    height: 1px;
    font-size: 0;
    line-height: 0;
    margin: 0 8px;
  }
}
</style>
